// noinspection JSUnusedGlobalSymbols

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSquare as farSquare } from "@fortawesome/pro-regular-svg-icons";
/*
 * Alle importieren icons müssen im library.add voranden seien.
 */
import {
	faAlignCenter,
	faAlignLeft,
	faAlignRight,
	faArrowDownZA,
	faArrowsAltV,
	faArrowUp,
	faArrowUpAZ,
	faBackward,
	faBackwardFast,
	faBackwardStep,
	faBars,
	faBox,
	faBug,
	faCalendar,
	faCamera,
	faCaretDown,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleXmark,
	faCrosshairsSimple,
	faDotCircle,
	faDownload,
	faEdit,
	faExclamation,
	faExclamationTriangle,
	faEye,
	faEyeDropper,
	faEyeSlash,
	faFile,
	faFilm,
	faFloppyDisk,
	faFolderOpen,
	faForward,
	faForwardFast,
	faForwardStep,
	faFutbol,
	faHandPointer,
	faHourglass,
	faHourglassEnd,
	faHourglassStart,
	faInfoCircle,
	faMinus,
	faPaperclip,
	faPause,
	faPeopleArrows,
	faPersonBreastfeeding,
	faPersonBurst,
	faPersonCirclePlus,
	faPersonWalkingArrowRight,
	faPlay,
	faPlayCircle,
	faPlus,
	faRedo,
	faScissors,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faStarHalf,
	faStepBackward,
	faStepForward,
	faStop,
	faSync,
	faText,
	faTimes,
	faTimesCircle,
	faTrafficCone,
	faTrash,
	faUndo,
	faWrenchSimple,
	faXmark,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faAlignCenter,
	faAlignLeft,
	faAlignRight,
	faArrowDownZA,
	faArrowsAltV,
	faArrowUp,
	faArrowUpAZ,
	faBackward,
	faBackwardFast,
	faBackwardStep,
	faBars,
	faBox,
	faBug,
	faCalendar,
	faCamera,
	faCaretDown,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleXmark,
	faCrosshairsSimple,
	faDotCircle,
	faDownload,
	faEdit,
	faExclamation,
	faExclamationTriangle,
	faEye,
	faEyeDropper,
	faEyeSlash,
	faFile,
	faFilm,
	faFloppyDisk,
	faFolderOpen,
	faForward,
	faForwardFast,
	faForwardStep,
	faFutbol,
	faHandPointer,
	faHourglass,
	faHourglassEnd,
	faHourglassStart,
	faInfoCircle,
	faMinus,
	faPaperclip,
	faPause,
	faPeopleArrows,
	faPersonBreastfeeding,
	faPersonBurst,
	faPersonCirclePlus,
	faPersonWalkingArrowRight,
	faPlay,
	faPlayCircle,
	faPlus,
	faRedo,
	faScissors,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faStarHalf,
	faStepBackward,
	faStepForward,
	faStop,
	faSync,
	faText,
	faTimes,
	faTimesCircle,
	faTrafficCone,
	faTrash,
	faUndo,
	faWrenchSimple,
	faXmark,
);
library.add(farSquare);

const vuetifyIcons = {
	calendar: faCalendar.iconName,
	cancel: faTimesCircle.iconName,
	checkboxIndeterminate: faSquareMinus.iconName,
	checkboxOff: farSquare.iconName, // note far, weil faSquare ausgefüllt ist
	checkboxOn: faSquareCheck.iconName,
	clear: faTimesCircle.iconName,
	close: faTimes.iconName,
	collapse: faChevronUp.iconName,
	complete: faCheck.iconName,
	delete: faTimesCircle.iconName,
	delimiter: faCircle.iconName,
	dropdown: faCaretDown.iconName,
	edit: faEdit.iconName,
	error: faExclamationTriangle.iconName,
	expand: faChevronDown.iconName,
	eyeDropper: faEyeDropper.iconName,
	file: faPaperclip.iconName,
	first: faStepBackward.iconName,
	info: faInfoCircle.iconName,
	last: faStepForward.iconName,
	loading: faSync.iconName,
	menu: faBars.iconName,
	minus: faMinus.iconName,
	next: faChevronRight.iconName,
	plus: faPlus.iconName,
	prev: faChevronLeft.iconName,
	radioOff: faCircle.iconName,
	radioOn: faDotCircle.iconName,
	ratingEmpty: faStar.iconName,
	ratingFull: faStar.iconName,
	ratingHalf: faStarHalf.iconName,
	sortAsc: faArrowUpAZ.iconName,
	sortDesc: faArrowDownZA.iconName,
	subgroup: faCaretDown.iconName,
	success: faCheckCircle.iconName,
	unfold: faArrowsAltV.iconName,
	warning: faExclamation.iconName,
};

const tstIcons = {
	addArrow: faArrowUp.iconName,
	addProp: faTrafficCone.iconName,
	addText: faText.iconName,
	alignCenter: faAlignCenter.iconName,
	alignLeft: faAlignLeft.iconName,
	alignRight: faAlignRight.iconName,
	appendIdleSegment: faHourglass.iconName,
	appendMoveSegment: faPersonWalkingArrowRight.iconName,
	backward: faBackward.iconName,
	ballAdd: faFutbol.iconName,
	blockBall: faPersonBurst.iconName,
	bugreport: faBug.iconName,
	captureScreenshot: faCamera.iconName,
	deleteFile: faTrash.iconName,
	dialogClose: vuetifyIcons["close"],
	download: faDownload.iconName,
	forward: faForward.iconName,
	hidden: faEyeSlash.iconName,
	insertIdleSegmentAfter: faHourglassEnd.iconName,
	insertIdleSegmentBefore: faHourglassStart.iconName,
	jumpToEnd: faForwardFast.iconName,
	jumpToStart: faBackwardFast.iconName,
	loadFile: faFolderOpen.iconName,
	newFile: faFile.iconName,
	pause: faPause.iconName,
	pickUpBall: faPersonBreastfeeding.iconName,
	playerAdd: faPersonCirclePlus.iconName,
	playPass: faPeopleArrows.iconName,
	propBallBox: faBox.iconName,
	propCone: faTrafficCone.iconName,
	redo: faRedo.iconName,
	removeLastSegment: faCircleXmark.iconName,
	removeSelected: faTrash.iconName,
	saveFile: faFloppyDisk.iconName,
	select: faHandPointer.iconName,
	showDebugLayer: faWrenchSimple.iconName,
	shown: faEye.iconName,
	slowlyBackward: faBackwardStep.iconName,
	slowlyForward: faForwardStep.iconName,
	splitSegment: faScissors.iconName,
	start: faPlay.iconName,
	stop: faStop.iconName,
	throwBall: faCrosshairsSimple.iconName,
	undo: faUndo.iconName,
	videoExport: faFilm.iconName,
	viewer: faPlayCircle.iconName,
};

const icons = {
	...vuetifyIcons,
	...tstIcons,
};

export type Icon = `$${keyof typeof icons}`;
export default icons;
