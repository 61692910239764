<template>
	<v-dialog
		v-for="dialog in dialogs"
		:model-value="true"
		:key="dialog.id"
		:scrollable="true"
		transition="fade"
		width="auto"
		:style="{ display: !dialog.visible.value ? 'none' : undefined }"
		@update:model-value="
			(e: boolean) => {
				if (!e) {
					dialog.reject();
				}
			}
		"
	>
		<component
			:is="dialog.component"
			v-bind="dialog.props"
			@hide="() => dialog.hide()"
			@reveal="() => reveal(dialog)"
			@reject="dialog.reject"
			@resolve="dialog.resolve"
		/>
	</v-dialog>
</template>

<script setup lang="ts">
import { VDialog } from "vuetify/components/VDialog";
import { type Dialog, useDialog } from "../composable/dialog";

const { dialogs } = useDialog();
function reveal(d: Dialog) {
	d.show();
}
</script>
