import { Vector2 } from "@babylonjs/core/Maths/math.vector";
import { type DeepImmutable } from "@babylonjs/core/types";
import { type DeepReadonly, inject, type InjectionKey, provide, type Ref, ref } from "vue";
import type { Icon } from "../icons";
import { assertDefined } from "../lib/assert/assertDefined";
import { serial } from "../lib/Serial";

export type ContextMenuItem = {
	label: string;
	icon: Icon;
	perform(): void;
};

type ContextMenuDefinition = {
	readonly id: number;
	readonly position: DeepImmutable<Vector2>;
	readonly items: readonly ContextMenuItem[];
	readonly evt: DeepReadonly<MouseEvent>;
};

type ContextMenuOpenFunction = (
	position: DeepImmutable<Vector2>,
	menu: readonly ContextMenuItem[],
	evt: DeepReadonly<MouseEvent>,
) => void;

export type ContextMenuData = {
	readonly menu: Ref<ContextMenuDefinition | null>;
	readonly open: ContextMenuOpenFunction;
	activate(menu: ContextMenuDefinition, item: ContextMenuItem): void;
	close(): void;
};

const ContextMenuInjectionKey = Symbol("TstContextMenu") as InjectionKey<ContextMenuData>;

export function provideContextMenu(): ContextMenuData {
	const idGenerator = serial(0);
	const menu = ref<ContextMenuDefinition | null>(null);

	function open(
		position: DeepImmutable<Vector2>,
		items: readonly ContextMenuItem[],
		evt: DeepReadonly<MouseEvent>,
	) {
		close();

		const id = idGenerator.next().value;
		menu.value = {
			id,
			position,
			items,
			evt,
		};
	}

	function activate(menu: ContextMenuDefinition, item: ContextMenuItem): void {
		item.perform();
		close();
	}

	function close() {
		menu.value = null;
	}

	const data: ContextMenuData = {
		menu,
		activate,
		open,
		close,
	};

	provide(ContextMenuInjectionKey, data);
	return data;
}

export function useContextMenu(): ContextMenuData {
	const data = inject(ContextMenuInjectionKey);
	assertDefined(data, "Context menu has not been provided");
	return data;
}

export function useOpenContextMenu() {
	const data = inject(ContextMenuInjectionKey);
	assertDefined(data, "Context menu has not been provided");
	return (evt: MouseEvent, menu: ContextMenuItem[]) => {
		evt.preventDefault();
		evt.stopPropagation();
		data.open(new Vector2(evt.x, evt.y), menu, evt);
	};
}
