import type { InjectionKey } from "vue";
import type { GitLab } from "./GitLab";
import type { PersistentStorage } from "./PersistentStorage";

// @todo rename Is* to Implements*
export const IsEntity = Symbol("IsSymbol");
export const IsSegmentDrawable = Symbol("IsSegmentDrawable");
export const IsSegmentWritableDuration = Symbol("IsSegmentWritableDuration");
export const IsSegmentWritableSpeed = Symbol("IsSegmentWritableSpeed");
export const IsSelectable = Symbol("IsSelectable");
export const GitLabKey: InjectionKey<GitLab> = Symbol("Gitlab");
export const StorageKey: InjectionKey<PersistentStorage> = Symbol("Storage");
