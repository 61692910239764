import { type IDBPDatabase, type IDBPTransaction, openDB, type StoreNames } from "idb";
import { DB_NAME } from "../config";
import { assertDate } from "./assert/assertDate";
import { assertNumber } from "./assert/assertNumber";
import type { PersistentStorage, ScenarioList } from "./PersistentStorage";
import type { SerializedScenario } from "./Serialization";

type Schema = {
	GamePlans: null;
	Scenarios: {
		key: number;
		value: SerializedScenario;
		indexes: {
			date: Date;
		};
	};
};

type MigrateFunc = (
	db: IDBPDatabase<Schema>,
	tx: IDBPTransaction<Schema, StoreNames<Schema>[], "versionchange">,
	event: IDBVersionChangeEvent,
) => Promise<void>;

const migrations: Array<MigrateFunc> = [
	db => {
		const store = db.createObjectStore("GamePlans", { keyPath: "id", autoIncrement: true });
		store.createIndex("date", "date", { unique: false });
		return Promise.resolve();
	},
	async (db, tx) => {
		const store = db.createObjectStore("Scenarios", { keyPath: "id", autoIncrement: true });
		store.createIndex("date", "date", { unique: false });
		if (db.objectStoreNames.contains("GamePlans")) {
			const gpStore = tx.objectStore("GamePlans");
			const gps = await gpStore.getAll();
			const puts = [];
			for (const gp of gps) {
				puts.push(store.put(gp));
			}
			await Promise.all(puts);
			db.deleteObjectStore("GamePlans");
		}
		return Promise.resolve();
	},
];

export class PersistentStorageIdb implements PersistentStorage {
	protected db;

	constructor() {
		this.db = openDB<Schema>(DB_NAME, 2, {
			blocked(currentVersion: number, blockedVersion: number | null): void {
				console.error(
					"Cannot upgrade from " +
						currentVersion +
						" to " +
						blockedVersion +
						" as we are blocked",
				);
				throw Error("Indexeddb upgrade failed");
			},
			blocking(currentVersion: number, blockedVersion: number | null): void {
				console.error("Blocking update from " + currentVersion + " to " + blockedVersion);
			},
			async upgrade(
				db: IDBPDatabase<Schema>,
				oldVersion: number,
				newVersion: number | null,
				tx: IDBPTransaction<Schema, StoreNames<Schema>[], "versionchange">,
				event: IDBVersionChangeEvent,
			): Promise<void> {
				console.info(
					"Upgrading indexedDB schema from " +
						oldVersion +
						(newVersion ? " to " + newVersion : ""),
				);
				for (const [v, m] of migrations.entries()) {
					if (v >= oldVersion && (newVersion == null || v < newVersion)) {
						console.info("executing migration " + v);
						// Must execute in order
						// eslint-disable-next-line no-await-in-loop
						await m(db, tx, event);
					}
				}
			},
		});
	}

	async list(): Promise<ScenarioList> {
		const db = await this.db;
		const result: ScenarioList = [];
		let cursor = await db
			.transaction("Scenarios", "readonly")
			.store.index("date")
			.openKeyCursor(undefined, "prev");
		while (cursor) {
			assertDate(cursor.key);
			assertNumber(cursor.primaryKey);
			result.push({ id: cursor.primaryKey, date: cursor.key });
			// eslint-disable-next-line no-await-in-loop
			cursor = await cursor.continue();
		}
		return result;
	}

	async load(id: number): Promise<SerializedScenario | undefined> {
		const db = await this.db;
		return db.get("Scenarios", id);
	}

	async save(gp: SerializedScenario): Promise<number> {
		const db = await this.db;
		const id = await db.put("Scenarios", gp);
		assertNumber(id);
		return id;
	}

	async delete(id: number): Promise<void> {
		const db = await this.db;
		return db.delete("Scenarios", id);
	}
}
