<template>
	<dialog ref="dialog" open>
		<v-list class="rounded" density="compact">
			<v-list-item
				v-for="(item, key) of items"
				:key="key"
				:title="item.label"
				density="compact"
				@click="emit('activated', item)"
			>
				<template #prepend>
					<v-icon :icon="item.icon" />
				</template>
			</v-list-item>
		</v-list>
	</dialog>
</template>

<script setup lang="ts">
import type { Vector2 } from "@babylonjs/core/Maths/math.vector";
import type { DeepImmutable } from "@babylonjs/core/types";
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";
import { VIcon } from "vuetify/components/VIcon";
import { VList, VListItem } from "vuetify/components/VList";
import type { ContextMenuItem } from "../composable/contextMenu";

const props = defineProps<{
	position: DeepImmutable<Vector2>;
	items: readonly ContextMenuItem[];
}>();

const emit = defineEmits<{
	(e: "activated", item: ContextMenuItem): void;
	(e: "close"): void;
}>();

const dialog = ref<HTMLDialogElement>();
onClickOutside(
	dialog,
	() => {
		emit("close");
	},
	{ capture: true },
);
const x = computed(() => props.position.x + "px");
const y = computed(() => props.position.y + "px");
</script>

<style scoped lang="scss">
@import "node_modules/vuetify/lib/styles/main";
dialog {
	position: fixed;
	border: none;
	z-index: 20000;
	@extend .rounded;
	@extend .elevation-5;
	margin: 0;
	left: 0;
	top: 0;
	transform: translateX(min(v-bind(x), calc(100vw - 100%)))
		translateY(min(v-bind(y), calc(100vh - 100%)));
}
</style>
