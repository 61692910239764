// noinspection JSUnusedGlobalSymbols

import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-ext-400.css";

import { config } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { createApp } from "vue";
// eslint-disable-next-line no-restricted-imports
import { createVuetify } from "vuetify";
import { md3 } from "vuetify/blueprints";
import "vuetify/dist/vuetify.css";
import { Resize } from "vuetify/directives";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import App from "./App.vue";
import "./assets/main.scss";
import i18n from "./i18n";
import icons from "./icons";
import { GitLab } from "./lib/GitLab";
import { PersistentStorageIdb } from "./lib/PersistentStorageIdb";

import { GitLabKey, StorageKey } from "./lib/symbols";
import router from "./router";

const app = createApp(App);

config.showMissingIcons = true;
config.styleDefault = "fas";

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(i18n);
app.use(
	createVuetify({
		blueprint: md3,
		icons: {
			defaultSet: "fa",
			aliases: {
				...aliases,
				...icons,
			},
			sets: {
				fa
			},
		},
		directives: {
			Resize,
		},
	}),
);
app.use(router);

app.provide(
	GitLabKey,
	new GitLab(
		import.meta.env.TST_GITLAB_ENDPOINT,
		import.meta.env.TST_GITLAB_PROJECT_ID,
		import.meta.env.TST_GITLAB_TOKEN,
	),
);

app.provide(StorageKey, new PersistentStorageIdb());

app.mount("#app");
