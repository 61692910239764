<template>
	<context-menu
		v-if="menu !== null"
		:key="menu.id"
		:items="menu.items"
		:position="menu.position"
		@activated="item => menu && activate(menu, item)"
		@close="close()"
	></context-menu>
</template>

<script setup lang="ts">
import { useContextMenu } from "../composable/contextMenu";
import ContextMenu from "./ContextMenu.vue";

const { menu, activate, close } = useContextMenu();
</script>
