import axios, { type Axios, AxiosError } from "axios";
import parseDataURL from "data-urls";

export class GitLabError extends Error {
	constructor(msg: string, errorCode?: number, cause?: Error) {
		const message = "GitLab" + (errorCode ? " (" + errorCode + ")" : "") + ": " + msg;
		super(message, { cause });
	}
}

export class GitLab {
	readonly gitVersion: string = __GIT_VERSION__;
	readonly buildDate: string = __BUILD_DATE__;
	readonly axios: Axios;

	constructor(endpoint: string, readonly projectId: string | number, readonly token: string) {
		this.axios = axios.create({
			baseURL: endpoint.replace(/\/*$/, "") + "/",
			headers: {
				"Private-Token": token,
			},
		});
	}

	async upload(dataUrl: string) {
		const n = Date.now() / 1000;
		const formData = new FormData();
		const file = this.dataURLtoFile(dataUrl, "bugreport-" + n + ".png");
		formData.set("file", file, file.name);
		try {
			const response = await this.axios.post(this.method("projects", "uploads"), formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			return response.data;
		} catch (e) {
			if (e instanceof AxiosError) {
				throw new GitLabError(
					"Failed to upload file (" + e.code + ")",
					e.response?.status,
					e,
				);
			} else if (e instanceof Error) {
				throw new GitLabError(e.message, undefined, e);
			}
		}
	}

	async createIssue(title: string, description: string, url: string, screenshot?: string) {
		let upload = null;
		if (screenshot) {
			upload = await this.upload(screenshot);
		}
		const txt =
			(upload ? upload.markdown + "\n\n" : "") +
			description +
			"\n\n" +
			(url ? "URL: " + url + "<br>" : "") +
			"\nRevision: " +
			this.gitVersion +
			"<br>" +
			"\nBuild date: " +
			this.buildDate;

		const postData = { title, description: txt };
		try {
			const response = await this.axios.post(this.method("projects", "issues"), postData);
			return response.data;
		} catch (e) {
			if (e instanceof AxiosError) {
				throw new GitLabError(
					"Failed to create issue (" + e.code + ")",
					e.response?.status,
					e,
				);
			} else if (e instanceof Error) {
				throw new GitLabError(e.message, undefined, e);
			}
		}
	}

	private dataURLtoFile(dataUrl: string, filename: string) {
		const url = parseDataURL(dataUrl);
		if (!url) {
			throw new GitLabError("Failed to parse dataURL");
		}

		return new File([url.body], filename, { type: url.mimeType.toString() });
	}

	private method(api: string, method: string): string {
		return api + "/" + this.projectId + "/" + method;
	}
}
