import { Scalar } from "@babylonjs/core/Maths/math.scalar";
import type { Radiants, Speed } from "./lib/units";

export const DEFAULT_SPEED: Speed = 5; // m/s
export const UNDO_STACK_SIZE = 10;
export const CONTROL_POINT_RADIUS = 0.25;
export const PASS_TARGET_RADIUS = CONTROL_POINT_RADIUS;
export const PASS_SOURCE_RADIUS = CONTROL_POINT_RADIUS;
export const EPSILON = 0.00001;
export const DELTA_T = Number.MIN_VALUE;
export const CURVE_RESOLUTION = 20; // points/m
export const CURVE_LUT_RESOLUTION = 20; // points/m

export const BALL_SPEED_MAX = 42; // m/s
export const RUN_SPEED_MAX = 10.3; // m/s

export const ROTATION_INCREMENT: Radiants = Scalar.TwoPi / 360;

export const DB_NAME = "tst";
