import { createI18n } from "vue-i18n";
import deDE from "./locale/de-DE";

type MessagesSchema = typeof deDE;

const i18n = createI18n<[MessagesSchema], "de-DE">({
	legacy: false,
	globalInjection: true,
	locale: "de-DE",
	messages: {
		"de-DE": deDE,
	},
	datetimeFormats: {
		"de-DE": {
			datetime: {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			},
		},
	},
});

export default i18n;
