<template>
	<v-app :full-height="true">
		<v-layout>
			<v-app-bar color="primary">
				<v-app-bar-nav-icon>
					<Logo :fill="onPrimary" height="48" width="48" />
				</v-app-bar-nav-icon>
				<v-app-bar-title> {{ t("app.title") }}</v-app-bar-title>
				<v-spacer />
				<div id="app-toolbar" />
			</v-app-bar>
			<v-main :scrollable="scrollable">
				<router-view />
			</v-main>
			<dialog-wrapper />
			<context-menu-wrapper />
		</v-layout>
	</v-app>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView, useRoute } from "vue-router";
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "vuetify";
import {
	VApp,
	VAppBar,
	VAppBarNavIcon,
	VAppBarTitle,
	VLayout,
	VMain,
	VSpacer,
} from "vuetify/components";
import Logo from "./assets/logo.svg?component";
import ContextMenuWrapper from "./components/ContextMenuWrapper.vue";
import DialogWrapper from "./components/DialogWrapper.vue";
import { provideContextMenu } from "./composable/contextMenu";
import { provideDialog } from "./composable/dialog";

const { t } = useI18n();
const route = useRoute();
const scrollable = computed(() => {
	const meta = route.meta;
	return Boolean(meta.scrollable ?? true);
});

const theme = useTheme();
const onPrimary = theme.current.value.colors["on-primary"];

provideDialog();
provideContextMenu();
</script>

<style lang="scss">
html {
	overflow-y: hidden;
	box-sizing: border-box;
}

.logo {
	display: inline-block;
}

#app-toolbar .v-btn--variant-plain.v-btn--disabled {
	opacity: 0.25;
}

#app-toolbar .v-btn--variant-plain {
	opacity: 0.8;
}

#app-toolbar .v-btn--variant-plain:hover {
	opacity: 1;
}
v-layout {
	overflow-y: auto;
}
</style>
