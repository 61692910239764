import {
	type AllowedComponentProps,
	type Component,
	type DeepReadonly,
	inject,
	type InjectionKey,
	provide,
	ref,
	type Ref,
	shallowReactive,
	type VNodeProps,
} from "vue";

let nextId = 0;

export type Dialog<R = any> = {
	readonly id: number;
	readonly component: Component;
	readonly props: DeepReadonly<Record<string, unknown>>;
	readonly visible: Ref<boolean>;
	hide(): void;
	show(): void;
	resolve(value: R): void;
	reject(): void;
};

type ComponentProps<C extends Component> = C extends new (...args: any) => any
	? Omit<InstanceType<C>["$props"], keyof VNodeProps | keyof AllowedComponentProps>
	: never;

export type DialogCtx = {
	dialogs: Dialog[];
	open<R, C extends Component>(component: C, props: ComponentProps<C>): Promise<R>;
};

export type DialogEvents<R = any> = {
	(e: "reject"): void;
	(e: "resolve", value: R): void;
	(e: "reveal"): void;
	(e: "hide"): void;
};

const DialogInjectionKey = Symbol("TstDialog") as InjectionKey<DialogCtx>;

export function provideDialog(): DialogCtx {
	const dialogs = shallowReactive<Dialog[]>([]);

	function remove(id: number) {
		const idx = dialogs.findIndex(d => d.id == id);
		dialogs.splice(idx, 1);
	}

	function open<C extends Component, R>(component: C, props: ComponentProps<C>): Promise<R> {
		const id = nextId++;
		const visible = ref(true);
		return new Promise<R>((resolveFn, rejectFn) => {
			dialogs.push({
				id,
				component,
				props: props ?? {},
				visible,
				hide(): void {
					visible.value = false;
				},
				show(): void {
					visible.value = true;
				},
				resolve(value: any): void {
					remove(id);
					resolveFn(value);
				},
				reject() {
					remove(id);
					rejectFn();
				},
			});
		});
	}

	const ctx = { dialogs, open };
	provide(DialogInjectionKey, ctx);
	return ctx;
}

export function useDialog(): DialogCtx {
	const ctx = inject(DialogInjectionKey);
	if (!ctx) {
		throw Error(
			"Could not inject dialog context. Was provideDialog() called in a parent component?",
		);
	}
	return ctx;
}
